import React from "react"

import 'bootstrap/dist/css/bootstrap.min.css'
import 'font-awesome/css/font-awesome.min.css';
import 'normalize.css'
import './src/styles/global.css'
import 'react-bootstrap-typeahead/css/Typeahead.css';

import { ApolloClient, InMemoryCache, HttpLink, concat, ApolloLink} from '@apollo/client';
import { ApolloProvider } from '@apollo/client'
import fetch from 'cross-fetch';

const httpLink = new HttpLink({
    uri: process.env.GATSBY_NOMBRAMIENTOS_API_URL,
    fetch
});

const API_KEY = process.env.GATSBY_APIKEY;
const API_SECRET = process.env.GATSBY_APISECRET;
const now = Date.now();
const text = `${API_KEY}:${API_SECRET}:${now}`;
const token = Buffer.from(text).toString('base64');


const authMiddleware = new ApolloLink((operation, forward) => {
    operation.setContext({
      headers: {
        authorization: `Bearer ${token} ` || null,
      }
    });  
    return forward(operation);
  });

const apolloClient = new ApolloClient({
     cache: new InMemoryCache(),
     link: concat(authMiddleware, httpLink),
});

export const wrapRootElement = ({element}) => {
    return(   
             <ApolloProvider client={apolloClient}>
             {element}
             </ApolloProvider>             
    )};
